import { PagedResultDto } from '../../../../models/PagedResultDto';
import { PersonProfile } from '../../../../models/PersonProfile';
import API from '../../../../services/Api';
import http from '../../../../services/httpService';
import {
  GetAdditionalData,
  GetCertificateOutput,
  GetPagedPersonManagementListDto,
  GetPersonManagementOutput,
  IFamilyMember,
  IGeneralEducationList,
  SetPersonInfo,
  UpdatePersonAccountDto,
  UpdatePersonManagementDto,
} from './dto';
import { EmployeeIntroType } from '../../../../enum/PersonManagement';
import { setSuspendFromWorkStatus } from './dto/setSuspendFromWorkStatus.dto';

class PersonManagementService {
  public async getPagedList(e: GetPagedPersonManagementListDto): Promise<PagedResultDto<GetPersonManagementOutput>> {
    const result = await http.get(`${API.PersonManagement}list`, { params: e });
    return result.data;
  }

  public async getById(id?: string): Promise<GetPersonManagementOutput> {
    const result = await http.get(`${API.PersonManagement}${id}`);
    return result.data;
  }

  public async updatePerson(e: UpdatePersonManagementDto): Promise<boolean> {
    const result = await http.put(`${API.PersonManagement}${e.id}`, e);
    return result.status === 200;
  }

  public async getPersonProfile(id?: string): Promise<PersonProfile> {
    const result = await http.get(`${API.PersonManagement}${id}/person-profile`);
    return result.data;
  }

  public async getAdditionalData(personId?: string): Promise<GetAdditionalData> {
    const result = await http.get(`${API.PersonManagement}${personId}/additional-data`);
    return result.data;
  }

  public async updatePersonProfile(e: UpdatePersonManagementDto): Promise<boolean> {
    const result = await http.put(`${API.PersonManagement}${e.id}/person-data`, e);
    return result.status === 200;
  }

  public async updatePersonAccount(e: UpdatePersonAccountDto): Promise<boolean> {
    const result = await http.put(`${API.PersonManagement}${e.id}/account-data`, e);
    return result.status === 200;
  }

  public async setAvatar(e: SetPersonInfo, id?: string): Promise<boolean> {
    const result = await http.put(`${API.PersonManagement}${id}/change-personal-data-by-user`, e);
    return result.status === 204;
  }

  public async getCerfitifcate(id: string) {
    const result = await http.get(`${API.PersonManagement}preview-certificate-as-pdf?resultId=${id}`, {
      responseType: 'blob',
    });
    return result;
  }

  public async getCerfitifcateAsJson(id: string): Promise<GetCertificateOutput> {
    const result = await http.get(`/api/persons-management/preview-certificate?resultId=${id}`);
    return result.data;
  }

  public async setIntroStatus(employeeIntroType: EmployeeIntroType, IntroStatus: boolean) {
    const result = await http.put(`${API.PersonManagement}set-intro-status`, {
      IntroStatus,
      EmployeeIntroType: employeeIntroType,
    });
    return result.data;
  }

  public async downloadCerfitifcate(id: string) {
    const result = await http.get(`${API.PersonManagement}preview-certificate-as-html?resultId=${id}`);
    return result.data;
  }

  public async getOtherSertificates(personId: string) {
    const result = await http.get(`${API.PersonManagement}${personId}/custom-certificate-list`);

    return result.data;
  }

  public async getFamilyMemberList(personId?: string): Promise<IFamilyMember[]> {
    const result = await http.get(`${API.FamilyMember}${personId}/list`);
    return result.data;
  }

  public async getGeneralEducationList(personId?: string): Promise<IGeneralEducationList[]> {
    const result = await http.get(`${API.LMS.GeneralEducation}list/${personId}`);
    return result.data;
  }

  public async setSuspendedFromWork(e: setSuspendFromWorkStatus) {
    const result = await http.put(`${API.PersonManagement}set-suspend-from-work-status`, { ...e });
    return result.data;
  }
}

export default new PersonManagementService();
